import { useEffect, useState } from 'react';
import FeedIcon from '@mui/icons-material/Feed';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import apiClient from '../../../../services/apiClient';
import ContractDetail from '../../../ContractList/ContractDetail/ContractDetail';
import Button from '@mui/material/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PublicNewsShow from '../../../PublicNews/PublicNewsShow/PublicNewsShow';

export default function PublicNewsSearch({
  filter,
  onNavigate,
}: {
  filter: any;
  onNavigate: () => void;
}) {
  const [publicNews, setPublicNews] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [publicNewsId, setPublicNewsId] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const { search, domain, category, group } = filter;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isClicked || !search) {
      setPublicNews(null);
      setPage(1);
    }
    if (search) {
      setIsLoading(true);

      apiClient
        .get('/news', {
          params: {
            page: page,
            perPage: 3,
            search,
          },
        })
        .then((res) => {
          if (isClicked) {
            res.data.records = (publicNews?.records || []).concat(
              res.data.records,
            );
          }
          setPublicNews(res.data);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setIsClicked(false);
          setIsLoading(false);
        });
    }
  }, [search, page]);

  const handleViewAll = () => {
    searchParams.delete('search');
    searchParams.set('search', search.toString());
    setSearchParams(searchParams);
    navigate({
      pathname: `/domains/updates`,
      search: searchParams.toString(),
    });
    onNavigate();
  };

  return (
    <>
      {(publicNews?.records?.length > 0 || isLoading) && (
        <Box sx={{ mt: 2 }}>
          <Divider />
          <Stack direction="row" paddingY={1.5} p={1} spacing={1}>
            <Typography color="primary">
              <FeedIcon />
            </Typography>
            <Typography color="gray">
              Public News {publicNews && `(${publicNews?.pagination.total})`}
            </Typography>
          </Stack>
          <Divider />
          {(publicNews?.records || []).map((contract: any, i: number) => (
            <CardActionArea
              key={i}
              sx={{ p: 1, px: 2 }}
              onClick={() => {
                setPublicNewsId(contract.id);
              }}
            >
              <Box sx={{ typography: 'subtitle2' }}>{contract.title}</Box>
              <Typography color="gray">
                <Stack direction="row" spacing={0.5}>
                  <Box>  {contract.excerpt.length > 180
                    ? contract.excerpt.slice(0, 180) + '...'
                    : contract.excerpt}</Box>
                </Stack>
              </Typography>
            </CardActionArea>
          ))}
          <Divider />
        </Box>
      )}

      {isLoading && (
        <>
          <Box sx={{ p: 2 }} width="100%">
            <Skeleton
              animation="wave"
              height={10}
              width="40%"
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={10} width="60%" />
          </Box>
        </>
      )}
      {publicNews?.records?.length !== 0 &&
        publicNews?.records?.length < publicNews?.pagination?.total && (
          <CardActionArea
            sx={{ p: 1, px: 2 }}
            onClick={() => {
              setIsLoading(true);
              setIsClicked(true);
              setPage(page + 1);
            }}
          >
            <Stack direction="row" spacing={1} p={1}>
              <ManageSearchIcon color="primary" />
              <Typography color="primary" style={{ marginTop: 2 }}>
                Show more public news
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                flexGrow={1}
                justifyContent="flex-end"
              >
                <Button onClick={handleViewAll} variant="outlined">
                  Open Public News
                </Button>
              </Stack>
            </Stack>
          </CardActionArea>
        )}
      <Divider />
      {publicNewsId && (
        // <ContractDetail
        //   handleContractDialogClose={() => setPublicNewsId(null)}
        //   publicNewsId={Number(publicNewsId)}
        //   isFeed={false}
        // />
        <PublicNewsShow id={Number(publicNewsId)} onClose={() => setPublicNewsId(null)} />

      )}
    </>
  );
}
