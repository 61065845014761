import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { makeStyles } from '@mui/styles';
import Layout from '../Layout/Layout';
import { useMatch, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import apiClient from '../../services/apiClient';
import { useSearchParams } from 'react-router-dom';

interface Report {
  reportId: string;
  settings: models.ISettings;
  filters: models.IFilter[];
}

interface Spend {
  record: Report;
  embedConfiguration: {
    token: string;
    url: string;
  };
}

export default function SpendPage() {
  const useStyles = makeStyles({
    embedContainer: {
      height: '100%',
      '& iframe': {
        border: 'none',
      },
      visualContainerHost: {
        outline: '0!important',
      },
    },
  });

  const handleHyperlinkClick = (e: any) => {
    window.scrollTo(0, 0);
    const url = e?.detail?.url.split('/');
    const id = url[url?.length - 1];
    if (id) {
      navigate(`/suppliers/${id}`);
    }
  };
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const supplierId = useMatch('/supplier-spend-financials/:id')?.params.id;
  const [spend, setSpend] = useState<Spend | null>(null);
  const [pageName, setPageName] = useState<string | undefined>(
    'ReportSection76dfb17c01045d2d10ad',
  );
  const navigate = useNavigate();
  const spendId = 1;
  const supplierFilter: any = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'myBuyIn referential_supplier',
      column: 'SupplierId',
    },
    operator: 'In',
    values: [Number(supplierId)],
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    apiClient
      .get(`/spends/${spendId}`, { params: { hasEmbedConfiguration: true } })
      .then(({ data }) => {
        setSpend(data);
      });
  }, [searchParams]);

  return (
    <Layout noBreadcrumbs>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" gap={1} sx={{ p: 1 }}>
          <InsightsIcon color="primary" sx={{ fontSize: 30 }} />
          <Typography component="h6" variant="h6">
            Supplier Spend & Financials
          </Typography>
        </Stack>

        {supplierId && (
          <ButtonGroup sx={{ m: 1 }}>
            <Button
              variant={
                pageName === 'ReportSection178ca7ac5b0700a6104b'
                  ? 'outlined'
                  : 'contained'
              }
              onClick={() => setPageName('ReportSection76dfb17c01045d2d10ad')}
            >
              Spend
            </Button>
            <Button
              variant={
                pageName === 'ReportSection76dfb17c01045d2d10ad'
                  ? 'outlined'
                  : 'contained'
              }
              onClick={() => setPageName('ReportSection178ca7ac5b0700a6104b')}
            >
              Financials
            </Button>
          </ButtonGroup>
        )}
      </Stack>
      <Divider />
      {supplierId && (
        <Box sx={{ height: '87vh', width: 'auto' }}>
          <PowerBIEmbed
            embedConfig={{
              accessToken: spend?.embedConfiguration.token,
              embedUrl: spend?.embedConfiguration.url,
              id: spend?.record.reportId,
              tokenType: models.TokenType.Embed,

              type: 'report',
              settings: {
                visualRenderedEvents: true,
                navContentPaneEnabled: false,
                hyperlinkClickBehavior:
                  models.HyperlinkClickBehavior.RaiseEvent,
                background: models.BackgroundType.Transparent,
                layoutType: models.LayoutType.Custom,
                customLayout: {
                  displayOption: models.DisplayOption.FitToPage,
                },
                ...(spend?.record.settings || {}),
              },
              filters: [...(spend?.record.filters || []), supplierFilter],
              pageName: pageName,
            }}
            cssClassName={classes.embedContainer}
            eventHandlers={
              new Map([
                ['dataHyperlinkClicked', handleHyperlinkClick],
                [
                  'loaded',
                  () => {
                    window.scrollTo(0, 0);
                  },
                ],
              ])
            }
          />
        </Box>
      )}

      {!supplierId && (
        <Box sx={{ height: '87vh', width: 'auto' }}>
          <PowerBIEmbed
            embedConfig={{
              accessToken: spend?.embedConfiguration.token,
              embedUrl: spend?.embedConfiguration.url,
              id: spend?.record.reportId,
              tokenType: models.TokenType.Embed,
              type: 'report',
              settings: {
                visualRenderedEvents: true,
                navContentPaneEnabled: false,
                hyperlinkClickBehavior:
                  models.HyperlinkClickBehavior.RaiseEvent,
                background: models.BackgroundType.Transparent,
                layoutType: models.LayoutType.Custom,
                customLayout: {
                  displayOption: models.DisplayOption.FitToPage,
                },
                ...(spend?.record.settings || {}),
              },
              filters:[...(spend?.record.filters || [])],
            }}
            cssClassName={classes.embedContainer}
            eventHandlers={
              new Map([
                ['dataHyperlinkClicked', handleHyperlinkClick],
                [
                  'loaded',
                  () => {
                    window.scrollTo(0, 0);
                  },
                ],
              ])
            }
          />
        </Box>
      )}
    </Layout>
  );
}
