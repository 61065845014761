import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, ButtonGroup, Divider, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import apiClient from '../../services/apiClient';

import ToolsGrid from './ToolsGrid';
import ToolsList from './ToolsList';
import PowerBiReports from '../DomainPage/PowerBiReports/PowerBiReports';
import Loader from '../ui/mui/Loader/Loader';
import { useAuth } from '../AuthProvider';
import { ROLE_HIERARCHY } from '../../data';
import { ErrorAlert } from '../ui/mui/ErrorAlert/ErrorAlert';

interface Report {
  id: string;
  name: string;
  powerBIId: string;
  isPaginated: boolean;
  iconId: number;
}

export default function ToolsPage() {
  const [displayReports, setDisplayReports] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [productCatalogs, setAllProductCatalogs] = useState<Report[]>([]);
  const [isList, setIsList] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);
  const [allReports, setAllReports] = useState<Report[]>([]);
  const [isTracked, setIsTracked] = useState<boolean>(false);
  const navigate = useNavigate();
  const { trackPageView } = useMatomo();
  const { profile } = useAuth();

  function hasRole(role: string): boolean {
    return Boolean(
      profile &&
        !profile.isCustomer &&
        ((profile?.roles || []).includes(role) ||
          Object.keys(ROLE_HIERARCHY)
            .filter((key) => (profile?.roles || []).includes(key))
            .some((key) => ROLE_HIERARCHY[key].includes(role))),
    );
  }

  useEffect(() => {
    if (!isTracked) {
      trackPageView({
        documentTitle: 'Tools/Tools_Page',
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  useEffect(() => {
    setDisplayReports(false);
    setIsList(true);
    setIsLoading(true);
    apiClient
      .get('/productCatalog', { params: { toDisplay: true } })
      .then((res: any) => {
        setIsLoading(false);
        setAllProductCatalogs(
          res.data.records.filter((pc: any) => pc.status === 1),
        );
      });
  }, [searchParams]);

  const handlePcClick = (r: any) => {
    setDisplayReports(true);
    trackPageView({
      documentTitle: `Tools/Product_Catalog_${r.name}`,
    });

    navigate({
      pathname: `/domains/tools/productCatalog/${r.id}`,
    });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Box display="flex" justifyContent="flex-end">
        <ButtonGroup sx={{ m: 2 }}>
          <Button
            variant={isList ? 'contained' : 'outlined'}
            onClick={() => {
              setIsList(true);
              setDisplayReports(false);
            }}
          >
            <AppsIcon></AppsIcon>
          </Button>
          <Button
            variant={!isList ? 'contained' : 'outlined'}
            onClick={() => {
              setIsList(false);
              setDisplayReports(false);
            }}
          >
            <ListIcon></ListIcon>
          </Button>
        </ButtonGroup>
      </Box>
      <Divider sx={{ marginBottom: 2 }} />

      {!displayReports &&
        productCatalogs.length === 0 &&
        allReports.length === 0 &&
        !hasRole(
          profile?.isCustomer ? 'TOOLS_DP-LLNB-LIVE' : 'ROLE_REPORT_USER',
        ) && (
          <Typography align="center" variant="subtitle2">
            <ErrorAlert
              message="No tool is available"
              severity="info"
              onClose={() => {
                navigate('/');
              }}
            />
          </Typography>
        )}

      <Grid container>
        {!displayReports && isList && (
          <ToolsList
            productCatalogs={productCatalogs}
            handlePcClick={handlePcClick}
          />
        )}
        {isList && <PowerBiReports setPowerBIReports={setAllReports} />}
      </Grid>

      {!displayReports && !isList && (
        <ToolsGrid
          allReports={allReports}
          productCatalogs={productCatalogs}
          onReportClick={() => setDisplayReports(true)}
          handlePcClick={handlePcClick}
        ></ToolsGrid>
      )}
    </>
  );
}
