import { useEffect, useState } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import ShareIcon from '@mui/icons-material/Share';
import newsClient from '../../../services/newsClient';
import PostSkeleton from '../PostSkeleton/PostSkeleton';
import ContentBox from '../shared/ContentBox/ContentBox';
import { Update } from '../types';
import { POST_TYPES } from '../data';
import { makeStyles } from '@mui/styles';

interface UpdateShowProps {
  id: number | null;
  onClose: () => void;
  isRoot: boolean;
}

export default function UpdateShow({ id, onClose, isRoot }: UpdateShowProps) {
  const [post, setPost] = useState<Update>();
  const [isRootPost, setIsRootPost] = useState<Boolean>(isRoot);
  const [rootPostId, setRootPostId] = useState<number | null>();
  const [previousPostId, setPreviousPostId] = useState<number | null>();
  const [likes, setLikes] = useState<number>(0);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const { trackPageView } = useMatomo();
  const { pushInstruction } = useMatomo();
  const navigate = useNavigate();
  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: 'blur(3px)',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    newsClient
      .post('posts', {
        post_type: [POST_TYPES.UPDATES],
        id: [id],
        has_related_posts: true,
      })
      .then((response) => {
        setPost(response.data);
        setLikes(response.data.likes);
        setIsLiked(response.data.is_liked);
        const postUrl = `${process.env.REACT_APP_MOBILE_API}/deeplink/updates/${id}`;
        trackPageView({
          documentTitle: 'Updates/Update_Detail',
        });
        pushInstruction(
          'trackContentImpression',
          `Update_${response.data.title.substring(0, 20)}`,
          postUrl,
        );
      })
      .catch((error) => {
        // TODO: handle errors
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleLikeClick = () => {
    setIsLiked(!isLiked);
    setLikes(isLiked ? likes - 1 : likes + 1);
    newsClient.post(`post-like/${id}`);
  };

  const handleArticleClick = (parentPostId: number, relatedPostId: number) => {
    setPost(undefined);
    setPreviousPostId(parentPostId);
    if (isRootPost) {
      setRootPostId(parentPostId);
      setIsRootPost(false);
    }
    navigate(`/domains/updates/${relatedPostId}`);
  };

  return (
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle>
        <Typography>&#8205;</Typography>
        {rootPostId && previousPostId && (
          <IconButton
            aria-label="back"
            onClick={() => {
              setPost(undefined);
              setPreviousPostId(null);
              navigate(`/domains/updates/${rootPostId}`);
            }}
            sx={{
              position: 'absolute',
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <IconButton
          aria-label="close"
          disabled={!post}
          href={`mailto:?subject=${post?.title}&body=Hey, I´d like to share this link with you %0A${process.env.REACT_APP_MOBILE_API}/deeplink/updates/${id}`}
          sx={{
            position: 'absolute',
            right: 50,
            top: 8,
            color: (theme) => theme.palette.error.light,
          }}
        >
          <ShareIcon />
        </IconButton>
      </DialogTitle>
      {post ? (
        <DialogContent>
          <Stack justifyContent="center" alignItems="center">
            <img alt={post.title} height={180} src={post.thumbnail} />
          </Stack>
          <Typography
            variant="h5"
            component="h1"
            fontWeight="bold"
            sx={{ mt: 1 }}
          >
            {post.title}
          </Typography>
          <Typography variant="caption" sx={{ color: 'gray' }}>
            {new Intl.DateTimeFormat('en-GB', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }).format(new Date(post.date))}{' '}
            By {post.author.name}
          </Typography>
          <Box sx={{ cursor: 'pointer' }} onClick={handleLikeClick}>
            <Stack direction="row" alignItems="center" gap={1}>
              {isLiked ? (
                <ThumbUpIcon color="primary" />
              ) : (
                <ThumbUpOffAltIcon color="primary" />
              )}
              {likes}
            </Stack>
          </Box>
          <Typography
            sx={{
              fontStyle: 'italic',
              color: (theme) => theme.palette.grey[500],
              pb: 1,
            }}
          >
            {post.excerpt}
          </Typography>
          <ContentBox content={post.content} />
          {post.attachments.length > 0 && (
            <Stack direction="row" spacing={1} pb={1}>
              <AttachmentIcon color="primary" />
              <Typography color="primary">Attachments</Typography>
            </Stack>
          )}
          {post.attachments.map((attachment, i) => (
            <Button
              key={i}
              variant="contained"
              color="inherit"
              sx={{ m: 1 }}
              startIcon={<AttachFileIcon />}
              href={attachment.url}
            >
              {attachment.name}
            </Button>
          ))}
          <Box sx={{ cursor: 'pointer', mt: 1 }} onClick={handleLikeClick}>
            <Stack direction="row" alignItems="center" gap={1}>
              {isLiked ? (
                <ThumbUpIcon color="primary" />
              ) : (
                <ThumbUpOffAltIcon color="primary" />
              )}
              {likes}
            </Stack>
          </Box>
          <Stack direction="row" alignItems="center" spacing={3} paddingY={2}>
            <Avatar
              alt={post.author.name}
              src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${post.author.email}`}
              sx={{ width: 87, height: 87 }}
            />
            <Typography>
              {post.author.name}
              <br />
              {post.author.email}
            </Typography>
          </Stack>
          {post.related_posts?.length > 0 && (
            <Stack direction="row">
              <Typography color="primary">Suggested for you</Typography>
            </Stack>
          )}
          <Grid container spacing={2} p={1} mb={3}>
            {post.related_posts?.map((related_post) => (
              <Grid item key={related_post.id} md={12 / 5} height={250}>
                <Paper
                  elevation={3}
                  onClick={() => handleArticleClick(post.id, related_post.id)}
                >
                  <CardActionArea sx={{ p: 2 }}>
                    <img
                      alt={related_post.title}
                      height={70}
                      src={related_post.thumbnail}
                    />
                    <br />
                    <Typography
                      variant="subtitle2"
                      component="h6"
                      height={40}
                      sx={{ wordWrap: 'break-word' }}
                    >
                      {related_post.title.length > 20
                        ? related_post.title.slice(0, 20) + '...'
                        : related_post.title}
                    </Typography>
                    <br />
                    <Typography variant="body2" height={70}>
                      {related_post.excerpt.length > 35
                        ? related_post.excerpt.slice(0, 35) + '...'
                        : related_post.excerpt}
                    </Typography>
                  </CardActionArea>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Stack direction="row" spacing={1}>
            <TurnedInIcon color="primary" />
            <Typography color="primary">Article tags</Typography>
          </Stack>
          <Box px={1}>
            {[
              ...post.domains,
              ...post.categories,
              ...post.products,
              ...post.groups,
              ...post.regions,
              ...post.natcos,
              ...post.topics,
              ...post.vendors,
            ].map((tag) => (
              <Chip key={tag.id} label={tag.name} sx={{ m: 0.3 }} />
            ))}
          </Box>
        </DialogContent>
      ) : (
        <PostSkeleton />
      )}
    </Dialog>
  );
}
