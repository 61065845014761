import * as React from 'react';
import { useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import HandymanIcon from '@mui/icons-material/Handyman';
import ContractsIcon from '../../shared/Icons/ContractIcon';
import FeedIcon from '../../shared/Icons/FeedIcon';
import UpdatesIcon from '../../shared/Icons/UpdatesIcon';
import SuppliersIcon from '../../shared/Icons/SuppliersIcon';
import NegotiationsIcon from '../../shared/Icons/NegotiationsIcon';
import StrategiesIcon from '../../shared/Icons/StrategiesIcon';
import ContactsIcon from '../../shared/Icons/ContactsIcon';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useLocation, useNavigate } from 'react-router';
import SustainabilityIcon from '../../shared/Icons/SustainabilityIcon';

export const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
  overflowX: 'hidden',
  backgroundColor: 'rgba(255,255,255,0.8)',
  marginTop: 64,
  height: 'calc(100% - 64px)',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'hidden',
  // marginTop: 64,
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
  marginTop: 64,
  height: 'calc(100% - 64px)',
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  zIndex: theme.zIndex.drawer,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      scrollbarWidth: 'thin',
      scrollbarColor: 'gray',
      '&::-webkit-scrollbar': {
        width: '7px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(255,255,255,0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray',
        borderRadius: '10px',
        border: `none`,
      },
    },
  }),
  ...(!open && {
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const StickyBottom = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'sticky',
  bottom: 0,
  // marginBottom: 64,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  zIndex: theme.zIndex.drawer + 1,
}));

export default function CustomerNavigation({ open, onMenuOpen }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPinned, setIsPinned] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const timeout: any = React.useRef();

  const debounce = () => {
    clearTimeout(timeout.current);
    if (!isScrolling) {
      setIsScrolling(true);
    }
    timeout.current = setTimeout(() => {
      setIsScrolling(false);
    }, 1000);
  };

  const navItems = [
    {
      path: '/domains/feed',
      icon: <FeedIcon />,
      label: 'Feed',
      subPath: '/feed',
    },
    {
      path: '/domains/updates',
      icon: <UpdatesIcon />,
      label: 'Updates',
      subPath: '/updates',
    },
    {
      path: '/supplier-spend-financials',
      icon: <SuppliersIcon />,
      label: 'Supplier Spend & Financials',
      subPath: '/supplier-spend-financials',
    },
    {
      path: '/domains/negotiations',
      icon: <NegotiationsIcon />,
      label: 'Negotiation Roadmap',
      subPath: '/negotiations',
    },

    {
      path: '/domains/contracts',
      icon: <ContractsIcon />,
      label: 'Contracts',
      subPath: '/contracts',
    },
    {
      path: '/domains/strategies',
      icon: <StrategiesIcon />,
      label: 'Strategies',
      subPath: '/strategies',
    },
    {
      path: '/domains/sustainability',
      icon: <SustainabilityIcon/>,
      label: 'Sustainability',
      subPath: '/sustainability',
    },
    {
      path: '/domains/contacts',
      icon: <ContactsIcon />,
      label: 'Contacts',
      subPath: '/contacts',
    },
    {
      path: '/domains/tools',
      icon: <HandymanIcon fontSize="small" />,
      label: 'Tools',
      subPath: '/tools',
    },
  ];

  return (
    <>
      <Box>
        <Drawer
          variant="permanent"
          open={open}
          onMouseEnter={() => {
            if (!open) {
              onMenuOpen(true);
              setIsScrolling(true);
            }
          }}
          onMouseLeave={() => {
            if (open && !isPinned) {
              onMenuOpen(false);
              setIsScrolling(false);
            }
          }}
          onWheel={debounce}
          onMouseMove={debounce}
          sx={{
            ...(isScrolling &&
              open && {
                '& .MuiDrawer-paper': {
                  overflowY: 'scroll',
                },
              }),
            ...(!isScrolling &&
              !open && {
                '& .MuiDrawer-paper': {
                  overflowY: 'hidden',
                },
              }),
          }}
        >
          <List sx={{ height: '100%' }}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  minHeight: 48,
                }}
                onClick={() => navigate('/')}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    p: 0.25,
                    mr: open ? 2.25 : 'auto',
                    justifyContent: 'center',
                    color: [
                      '/domains',
                      '/suppliers',
                      '/admin',
                      ...navItems.map((item) => item.path),
                    ].some((path) => location.pathname.startsWith(path))
                      ? 'inherit'
                      : 'primary.main',
                  }}
                >
                  <HomeIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary="Home"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: [
                      '/domains',
                      '/suppliers',
                      '/admin',
                      ...navItems.map((item) => item.path),
                    ].some((path) => location.pathname.startsWith(path))
                      ? 'inherit'
                      : 'primary.main',
                  }}
                />
              </ListItemButton>
            </ListItem>
            {navItems.map((navItem, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.25,
                  }}
                  onClick={() => {
                    onMenuOpen(false);
                    navigate(navItem.path);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color:
                        location.pathname.includes(navItem.path) ||
                        location.pathname.includes(navItem.subPath)
                          ? 'primary.main'
                          : 'inherit',
                    }}
                    className={
                      location.pathname.includes(navItem.path) ||
                      location.pathname.includes(navItem.subPath)
                        ? 'icon-selected'
                        : 'icon-default'
                    }
                  >
                    {navItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={navItem.label}
                    sx={{
                      marginLeft: navItem.label === "Sustainability"?'-5px':'0px',
                      opacity: open ? 1 : 0,
                      color:
                        location.pathname.includes(navItem.path) ||
                        location.pathname.includes(navItem.subPath)
                          ? 'primary.main'
                          : 'inherit',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          {open && (
            <>
              <StickyBottom>
                <Typography
                  variant="subtitle2"
                  sx={{ textAlign: 'center', marginRight: 2, p: 1 }}
                >
                  Version: MBI {process.env.REACT_APP_RELEASE}
                </Typography>
              </StickyBottom>
            </>
          )}
        </Drawer>
      </Box>
    </>
  );
}
