import { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { usePayload } from '../news/PayloadProvider';
import { Topic, Update } from '../news/types';
import newsClient from '../../services/newsClient';
import { POST_TYPES } from '../news/data';
import apiClient from '../../services/apiClient';
import { ALL, useNav } from '../NavProvider';
import TopicSelect from '../news/UpdateList/TopicSelect/TopicSelect';
import NewsList from '../news/NewsList/NewsList';
import PublicNewsList from '../PublicNews/PublicNewsList/PublicNewsList';
import UpdateShow from '../news/UpdateShow/UpdateShow';
import PublicNewsShow from '../PublicNews/PublicNewsShow/PublicNewsShow';
import Layout from '../Layout/Layout';
import Benchmark from './Benchmark';
// import Chat from './chat/Chat';


export default function Sustainability() {
  const { domainNodeId, supplierId, groupNodeId, setIsFrozen } = useNav();
  const navigate = useNavigate();
  const [isBenchmark, setIsBenchmark] = useState<boolean>(true);
  const pathPrefix = supplierId ? `/suppliers/${supplierId}` : '/domains';
  const [searchParams, setSearchParams] = useSearchParams();
  const [bot, setBot] = useState<any>();


  const newSearchParams = createSearchParams();
  if (domainNodeId && domainNodeId !== ALL) {
    newSearchParams.set('navDomain', domainNodeId);
  } else {
    newSearchParams.delete('navDomain');
  }
  if (groupNodeId && groupNodeId !== ALL) {
    newSearchParams.set('navGroup', groupNodeId);
  } else {
    newSearchParams.delete('navGroup');
  }

  useEffect(() => {
    apiClient
    .get(`/bots/Sustainability`)
    .then(({ data }) => {
      setBot(data);
    })
    .catch((error) => console.log(error))
    }, []);


  return (

    <>
      <Stack direction="row" justifyContent="space-between" sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
        <ButtonGroup variant="contained"
          aria-label="full width button group"
          sx={{ width: '100%' }}>
          <Button
            sx={{ flex: 1 }}
            variant={isBenchmark ? 'contained' : 'outlined'}
            onClick={() => setIsBenchmark(true)}
          >
            Sustainability Benchmark
          </Button>
          {/* <Button
          sx={{ flex: 1 }}
            variant={!isBenchmark ? 'contained' : 'outlined'}
            onClick={() => setIsBenchmark(false)}
          >
            Sustainability ChatBot
          </Button> */}
        </ButtonGroup>


      </Stack>
      <Divider />
     
      {/* {isBenchmark && ( */}
        <Benchmark/>
      {/* )} */}
      {/* {!isBenchmark && (
       <Chat
       title={bot.name}
       prompts={bot.prompts}
       index={bot.index}
       systemMessage={bot.systemMessage}
       botId={bot.id}/>
      )} */}
    </>
  );
}
