import React, { useEffect, useRef, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import SpeakerForm from '../SpeakerForm/SpeakerForm';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import apiClient from '../../../../../services/apiClient';
import { Domain, Group } from '../../../../../types';

interface TextFields {
  label: string;
  name:
    | 'title'
    | 'id'
    | 'description'
    | 'link'
    | 'type'
    | 'articleId'
    | 'duration';
  type: string;
  multiline?: boolean;
}

const byFirstName = (a: any, b: any) =>
  a.fistName > b.firstName ? 1 : a.firstName < b.firstName ? -1 : 0;

export default function SessionForm({
  open,
  groupRecords,
  domainRecords,
  setOpen,
  onChange,
}: {
  open: boolean;
  groupRecords: Partial<Group>[];
  domainRecords: Domain[];
  setOpen: (value: boolean) => void;
  onChange: () => void;
}) {
  const [step, setStep] = useState<number>(0);
  const [recap, setRecap] = useState<any>(null);
  const [search, setSearch] = useState('');
  const [inputValue, setInputValue] = useState<string>('');
  const [speakerRecords, setSpeakerRecords] = useState<any>([]);
  const steps = [
    { label: 'Add session' },
    { label: 'Assign/add speakers' },
    { label: 'Assign domains' },
    { label: 'Summary' },
  ];
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const handleClose = () => {
    setOpen(false);
    setStep(0);
  };
  const timeout: any = useRef();

  const handleInputChange = (event: any, value: any) => {
    clearTimeout(timeout.current);
    setInputValue(value.replace(/\s+/g, ' '));
    timeout.current = setTimeout(() => {
      setSearch(value.replace(/\s+/g, ' '));
    }, 300);
  };
  useEffect(() => {
    if (search) {
      apiClient
        .get('/contacts', {
          params: {
            hasSessions: true,
            hasOtherCategory: true,
            speaker: search,
            page: 1,
            perPage: 10,
            order: 'ASC',
            sortBy: 'firstName',
          },
        })
        .then((res: any) => {
          const records = res.data.records.filter(
            (record: any) =>
              !speakerRecords.some(
                (speaker: any) => speaker.id === record.id && speaker.isContact,
              ),
          );
          return records;
        })
        .then((contacts: any) => {
          apiClient
            .get('/speakers', {
              params: {
                search,
                page: 1,
                perPage: 10,
                order: 'ASC',
                sortBy: 'firstName',
              },
            })
            .then((res: any) => {
              const records = res.data.records.filter(
                (record: any) =>
                  !speakerRecords.some(
                    (speaker: any) =>
                      speaker.id === record.id && !speaker.isContact,
                  ),
              );
              setSpeakerRecords(
                speakerRecords
                  .concat(records)
                  .concat(contacts)
                  .sort(byFirstName),
              );
            });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, step]);

  const validationSchema = yup.object().shape({
    id: yup
      .number()
      .typeError('Id Must be a number')
      .required('the session Id is Required')
      .test(
        'Is positive?',
        'The session Id must be greater than 0.',
        (value: any) => value > 0,
      ),
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
    date: yup
      .date()
      .required('Date is required')
      .test('is null ?', 'Date is required', (value) => !!value)
      .typeError('Invalid Date'),
    duration: yup
      .number()
      .typeError('Id Must be a number')
      .required('the session Id is Required')
      .test(
        'Is positive?',
        'The session duration must be greater than 0.',
        (value: any) => value > 0,
      ),
    link: yup.string(),
    type: yup.string().required('Type is required'),
    isLocked: yup.boolean(),
    articleId: yup
      .number()
      .test(
        'Is positive?',
        'The article Id must be greater than 0',
        (value: any) => value > 0 || value === undefined,
      ),
    speakers:
      step === 1
        ? yup
            .array()
            .test(
              'Is Empty?',
              'At least one speaker is required',
              (value: any) => value.length > 0,
            )
        : yup.array(),
    group: yup.string().required('Group is required'),
    domains: yup.array(),
  });

  const properties: TextFields[] = [
    {
      label: 'Session ID',
      name: 'id',
      type: 'number',
    },
    {
      label: 'Title',
      name: 'title',
      type: 'text',
    },
    {
      label: 'Description',
      name: 'description',
      type: 'text',
      multiline: true,
    },
    {
      label: 'Duration',
      name: 'duration',
      type: 'number',
    },

    {
      label: 'Session link (Teams / Webex)',
      name: 'link',
      type: 'text',
    },
    {
      label: 'Type',
      name: 'type',
      type: 'text',
    },
    {
      label: 'ArticleId',
      name: 'articleId',
      type: 'number',
    },
  ];

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        id: '',
        duration: 60,
        title: '',
        description: '',
        type: '',
        date: null,
        link: '',
        group: '',
        isLocked: false,
        articleId: '',
        domains: [],
        speakers: [],
      }}
      onSubmit={(
        session: any,
        { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any },
      ) => {
        setSubmitting(false);
        if (step === 2) {
          setRecap(session);
        }
        if (step < 3) {
          setStep(step + 1);
          return;
        }
        setSubmitting(true);
        apiClient
          .post('admin/sessions', {
            ...session,
            article: {
              id: Number(session.articleId) || undefined,
              type: session.articleId && 'category-vendor-news',
            },
            date: new Date(session.date).toISOString(),
          })
          .then(() => {
            setOpenAlert(true);
            setMessage('Added successfully');
            setSeverity('success');
            onChange();
            resetForm();
            handleClose();
          })
          .catch((e) => {
            if ((e.message = 'SESSION_ALREADY_EXISTS')) {
              setMessage(
                `Session with ID ${session.id} already exists. Please use an other ID`,
              );
            } else {
              setMessage(e.message);
            }
            setOpenAlert(true);
            setSeverity('error');
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        errors,
        touched,
        handleSubmit,
        submitForm,
        setFieldValue,
        isSubmitting,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ pt: 2 }}>
              <Stepper activeStep={step} alternativeLabel>
                {steps.map((s: any, index) => (
                  <Step key={index} active={step === index}>
                    <StepLabel> {s.label} </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </DialogTitle>
            <DialogContent dividers>
              <Grid sx={{ p: 1 }}>
                {step === 0 && (
                  <>
                    {properties.map((prop: TextFields, index) => (
                      <Field name={prop.name} key={index}>
                        {({ field }: { field: any }) => (
                          <TextField
                            {...field}
                            margin="dense"
                            variant="filled"
                            multiline={prop.multiline || false}
                            helperText={
                              Boolean(touched[prop.name]) && errors[prop.name]
                            }
                            error={Boolean(
                              errors[prop.name] && touched[prop.name],
                            )}
                            label={prop.label}
                            type={prop.type}
                            fullWidth
                          />
                        )}
                      </Field>
                    ))}
                    <Field name="date">
                      {({ field }: { field: any }) => (
                        <FormControl fullWidth sx={{ mt: 1 }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              {...field}
                              ampm={false}
                              inputFormat="dd/MM/yyyy HH:mm"
                              label="Date and time"
                              onChange={(value: any) => {
                                setFieldValue('date', value);
                              }}
                              renderInput={(params: any) => (
                                <TextField
                                  margin="dense"
                                  variant="filled"
                                  error={Boolean(errors.date)}
                                  helperText={errors.date}
                                  value={field.value}
                                  {...params}
                                />
                              )}
                            />
                            {/* <FormHelperText>{errors.date}</FormHelperText> */}
                          </LocalizationProvider>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="group">
                      {({ field }: { field: any }) => (
                        <FormControl
                          fullWidth
                          sx={{ mt: 1 }}
                          error={Boolean(errors.group)}
                        >
                          <InputLabel sx={{ mt: 1 }}>Group</InputLabel>
                          <Select margin="dense" variant="filled" {...field}>
                            {groupRecords.map((group, index) => (
                              <MenuItem key={index} value={group.id}>
                                {group.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {touched.group && errors.group}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                    {
                      <Field sx={{ mt: 1 }} name="isLocked" label="Lock">
                        {({
                          field,
                        }: {
                          field: any;
                          values: any;
                          setFieldValue: any;
                        }) => {
                          return (
                            <>
                              Locked:
                              <Switch checked={field.value} {...field} />
                            </>
                          );
                        }}
                      </Field>
                    }
                  </>
                )}

                {step === 1 && (
                  <>
                    <Field name="speakers">
                      {({ field }: { field: any }) => (
                        <Autocomplete
                          sx={{ mt: 2 }}
                          multiple
                          {...field}
                          onChange={(event: any, v) => {
                            setFieldValue('speakers', v);
                          }}
                          inputValue={inputValue}
                          onInputChange={handleInputChange}
                          options={speakerRecords}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id &&
                            option.isContact === value.isContact
                          }
                          disableCloseOnSelect
                          fullWidth
                          getOptionLabel={(option: any) =>
                            `${option.firstName} ${option.lastName} ${option.email}`
                          }
                          renderTags={(values, getTagProps) =>
                            values.map((option: any, index: any) => (
                              <Chip
                                variant="filled"
                                sx={{
                                  color: 'black',
                                  background: 'white',
                                  border: '1px solid #bdbdbd',
                                }}
                                avatar={
                                  <Avatar
                                    alt={`${option.email}`}
                                    src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${option.email}`}
                                  />
                                }
                                label={`${option.firstName} ${option.lastName}`}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderOption={(
                            props: any,
                            option: any,
                            { selected }: { selected: boolean },
                          ) => (
                            <Box
                              container="li"
                              {...props}
                              key={`${option.id}${option.isContact}`}
                            >
                              <Checkbox
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {`${option.firstName} ${option.lastName} (${option.email})`}
                            </Box>
                          )}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              helperText={
                                Boolean(touched.speakers) && errors.speakers
                              }
                              error={Boolean(
                                touched.speakers && errors.speakers,
                              )}
                              variant="filled"
                              label={`Speakers${
                                field?.value?.length > 0
                                  ? ' (' + field.value.length + ')'
                                  : ''
                              }`}
                              placeholder="Search speakers ..."
                            />
                          )}
                        />
                      )}
                    </Field>
                    <SpeakerForm
                      onSave={(speaker) => {
                        setSpeakerRecords([...speakerRecords, speaker]);
                        console.log(values);
                        setFieldValue(
                          'speakers',
                          [...values.speakers, speaker].sort(byFirstName),
                        );
                      }}
                    />
                  </>
                )}

                {step === 2 && (
                  <>
                    <Field name="domains">
                      {({ field }: { field: any }) => {
                        return (
                          <>
                            <Autocomplete
                              multiple
                              getOptionLabel={(option: any) => option.name}
                              options={domainRecords}
                              value={field.value}
                              isOptionEqualToValue={(option: any, value: any) =>
                                option.id === value.id
                              }
                              {...field}
                              onChange={(event, value) => {
                                setFieldValue('domains', value);
                              }}
                              renderTags={(values, getTagProps) =>
                                values.map((option: any, index: any) => (
                                  <Chip
                                    variant="filled"
                                    sx={{
                                      color: 'black',
                                      background: 'white',
                                      border: '1px solid #bdbdbd',
                                    }}
                                    label={`${option.name}`}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderOption={(
                                props: any,
                                option: any,
                                { selected }: { selected: boolean },
                              ) => (
                                <Box
                                  container="li"
                                  {...props}
                                  key={`${option.id}`}
                                >
                                  <Checkbox
                                    icon={
                                      <CheckBoxOutlineBlankIcon fontSize="small" />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon fontSize="small" />
                                    }
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {`${option.name}`}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label={`Domains (${
                                    field?.value?.length === 0
                                      ? 'ALL'
                                      : field?.value?.length
                                  })`}
                                  placeholder="Select domains"
                                />
                              )}
                            />
                          </>
                        );
                      }}
                    </Field>
                  </>
                )}
                {step === 3 && (
                  <Grid
                    container
                    sx={{ p: 2, mt: -1 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h5">{recap?.title}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          {moment(recap?.date).format('DD/MM/YY hh:mm')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                          {recap?.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2" gutterBottom>
                          ID
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography gutterBottom>{recap?.id}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2">Duration</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography gutterBottom>
                          {recap?.duration} minutes
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2" gutterBottom>
                          Session link (Teams / Webex)
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography gutterBottom>{recap?.link}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2" gutterBottom>
                          Group
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography gutterBottom>
                          {
                            groupRecords.find(
                              (group) => Number(recap?.group) === group.id,
                            )?.name
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2" gutterBottom>
                          Type
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography gutterBottom>{recap?.type}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2" gutterBottom>
                          Article Id
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography gutterBottom>{recap?.articleId}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2" gutterBottom>
                          Domains
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        {recap?.domains?.length === 0
                          ? 'ALL'
                          : recap.domains.map((domain: any, index: any) => (
                              <Chip
                                key={index}
                                variant="filled"
                                sx={{
                                  color: 'black',
                                  background: 'white',
                                  border: '1px solid #bdbdbd',
                                  m: 0.5,
                                }}
                                label={`${domain.name}`}
                              />
                            ))}
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={3}
                          paddingY={2}
                        >
                          {recap?.speakers?.map((contact: any) => (
                            <React.Fragment key={contact.id}>
                              <Avatar
                                alt={`${contact.firstName} ${contact.lastName}`}
                                src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${contact.email}`}
                                sx={{ width: 87, height: 87 }}
                              />
                              <Typography>
                                {contact.firstName} {contact.lastName}
                                <br />
                                {contact.email}
                                <br />
                                {contact.fixed}
                              </Typography>
                            </React.Fragment>
                          ))}
                        </Stack>
                      </Grid>
                      <Box sx={{ width: '100%', mt: 2 }}>
                        <Collapse in={openAlert}>
                          <Alert
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpenAlert(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                            sx={{ mb: 2 }}
                            severity={severity}
                          >
                            {message}
                          </Alert>
                        </Collapse>
                      </Box>
                    </>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button startIcon={<CancelIcon />} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                startIcon={<NavigateBeforeIcon />}
                disabled={step === 0}
                onClick={() => {
                  setStep(step - 1);
                }}
              >
                Previous
              </Button>
              <LoadingButton
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={step < 3 ? <NavigateNextIcon /> : <SaveIcon />}
                onClick={submitForm}
              >
                {step < 3 ? 'Next' : 'Save'}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
