import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useNav } from '../NavProvider';
import { useEffect, useState } from 'react';
import apiClient from '../../services/apiClient';
import { Supplier } from '../../types';
import React from 'react';
import backgroundGreen from '../../images/swotAnalysis/back_green.png';
import backgroundBlue from '../../images/swotAnalysis/back_blue.png';
import backgroundYellow from '../../images/swotAnalysis/back_yellow.png';
import backgroundRed from '../../images/swotAnalysis/back_red.png';
import backgroundGrey from '../../images/swotAnalysis/back_grey.png';



export default function SwotAnalysis() {
  const { supplierId } = useNav();
  const [supplier, setSupplier] = useState<Supplier>();


  const TextWithSpace = ({ text }: any) => {
    return (
      <>
        {text?.split('\n')?.map((line: string, i: number) => (
          <React.Fragment key={i}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </>
    );
  };


  useEffect(() => {
    if (supplierId) {
      apiClient
        .get(`/suppliers/${supplierId}`, {
          params: { hasSwotAnalysis: true, hasSustainabilityScores: true, hasRating: true },
        })
        .then(({ data }) => {
          setSupplier(data.record);

        });
    }
  }, [supplierId]);

  return (
    <Box sx={{ p: 1 }}>
      <List sx={{ width: '100%' }}>
        {!supplier && (
          <>
            <Stack direction={'column'} spacing={1}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="70%" />
              <Skeleton height={10} width="80%" />
              <Divider />
            </Stack>

            {Array(3)
              .fill(null)
              .map((element, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  margin={2}
                >
                  <Skeleton height={10} width="100%" />
                  <Skeleton variant="circular" width={24} height={24} />
                </Stack>
              ))}
            <Divider />
            {Array(3)
              .fill(null)
              .map((element, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  margin={2}
                >
                  <Skeleton height={10} width="100%" />
                  <Skeleton variant="circular" width={24} height={24} />
                </Stack>
              ))}
          </>
        )}
        {supplier?.swotAnalysis && supplier?.swotAnalysis?.length > 0 && (
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <Card sx={{ height: '98%', border: '1px solid lightgrey', p: 1 }}>
                <CardHeader
                  sx={{ p: 1 }}
                  title={
                    <Box
                      sx={{
                        typography: 'body1',
                        textTransform: 'uppercase',
                        fontWeight: '500',
                        backgroundImage: `url(${backgroundGreen})`,
                        p: 2,
                        color: 'white'
                      }}
                    >
                      Strengths
                    </Box>
                  }

                ></CardHeader>
                <Box sx={{ p: 1 }}>
                  <Divider sx={{ background: 'red' }} />
                </Box>
                <CardContent sx={{ p: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: '400',
                      fontSize: '15px',
                      textAlign: 'justify',
                    }}
                  >
                    <TextWithSpace text={supplier?.swotAnalysis[0].strengths} />

                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ height: '98%', border: '1px solid lightgrey', p: 1 }}>
                <CardHeader
                  sx={{ p: 1 }}

                  title={
                    <Box
                      sx={{
                        typography: 'body1',
                        textTransform: 'uppercase',
                        fontWeight: '500',
                        backgroundImage: `url(${backgroundYellow})`,
                        p: 2,
                        color: 'white'
                      }}
                    >
                      Weaknesses
                    </Box>
                  }

                ></CardHeader>
                <Box sx={{ p: 1 }}>
                  <Divider sx={{ background: 'red' }} />
                </Box>
                <CardContent sx={{ p: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: '400',
                      fontSize: '15px',
                      textAlign: 'justify',
                    }}
                  >
                    <TextWithSpace text={supplier?.swotAnalysis[0].weaknesses} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ height: '98%', border: '1px solid lightgrey', p: 1 }}>
                <CardHeader
                  sx={{ p: 1 }}

                  title={
                    <Box
                      sx={{
                        typography: 'body1',
                        textTransform: 'uppercase',
                        fontWeight: '500',
                        backgroundImage: `url(${backgroundBlue})`,
                        p: 2,
                        color: 'white'
                      }}
                    >
                      Opportunities
                    </Box>
                  }
                ></CardHeader>
                <Box sx={{ p: 1 }}>
                  <Divider sx={{ background: 'red' }} />
                </Box>
                <CardContent sx={{ p: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: '400',
                      fontSize: '15px',
                      textAlign: 'justify',
                    }}
                  >
                    <TextWithSpace text={supplier?.swotAnalysis[0].opportunities} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ height: '98%', border: '1px solid lightgrey', p: 1 }}>
                <CardHeader
                  sx={{ p: 1 }}
                  title={
                    <Box
                      sx={{
                        typography: 'body1',
                        textTransform: 'uppercase',
                        fontWeight: '500',
                        backgroundImage: `url(${backgroundRed})`,
                        p: 2,
                        color: 'white'
                      }}
                    >
                      Threats
                    </Box>
                  }
                ></CardHeader>
                <Box sx={{ p: 1 }}>
                  <Divider sx={{ background: 'red' }} />
                </Box>
                <CardContent sx={{ p: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: '400',
                      fontSize: '15px',
                      textAlign: 'justify',
                    }}
                  >
                    <TextWithSpace text={supplier?.swotAnalysis[0].threats} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ height: '98%', border: '1px solid lightgrey', p: 1 }}>
                <CardHeader
                  sx={{ p: 1 }}
                  title={
                    <Box
                      sx={{
                        typography: 'body1',
                        textTransform: 'uppercase',
                        fontWeight: '500',
                        backgroundImage: `url(${backgroundGrey})`,
                        p: 2,
                        color: 'white'
                      }}
                    >
                      Conclusion
                    </Box>
                  }

                ></CardHeader>
                <Box sx={{ p: 1 }}>
                  <Divider sx={{ background: 'red' }} />
                </Box>
                <CardContent sx={{ p: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: '400',
                      fontSize: '15px',
                      textAlign: 'justify',
                    }}
                  >
                    <TextWithSpace text={supplier?.swotAnalysis[0].conclusion} />

                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">

                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '13px',
                    align: "right",
                    fontStyle: "italic",
                  }}
                >
                  AI generated content

                </Typography>
              </Box>
            </Grid>
          </Grid>)}
      </List>
    </Box>
  );
}
