import * as React from 'react';
import { SyntheticEvent, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '../../../ui/mui/DialogTitle/DialogTitle';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface FeedbackDialogProps {
  onClose: () => void;
}

export default function FaqDialog({ onClose }: FeedbackDialogProps) {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const FAQ =
    [{ identifiant: 'website', title: 'How does this website work?', content: '<p>Please watch our <a href="https://api.buyin.pro/mbinews/v3/public/resource/_9elPNePXcE_sTW_hcjXmOIZeGkb-3LeZO2co8UMllE#t=0.5" target="_blank">video guide</a>!</p>' },
    { identifiant: 'password', title: 'How do I change my password?', content: '<p style="text-align:justify">You can change your password by clicking on the Reset Password button in the Settings section of the website or by clicking on “Reset password“ on the Login screen, just below the red login button. You will receive an email on your inbox with a link to reset your password.</p>' },
    { identifiant: 'feedbak', title: 'What should I do if I want to provide feedback?', content: '<p style="text-align:justify">Please use the feedback feature of the website, you can access it by clicking on the "callout" icon on the very top right of the screen, we will be happy to hear from you.</p>' },
    { identifiant: 'quality', title: 'Who is responsible for the data quality and correctness of the data?', content: '<p style="text-align:justify">All data stored and presented in the app is collected and managed as carefully as possible. Nevertheless, as the data stem from several sources, processed in a multi tiered approach and also due to the sheer amount of data points we cannot guarantee its correctness. Therefore always double check before using it for impacting business decisions.</p>' },
    { identifiant: 'roadmap', title: 'How do I check the Negotiation Roadmap?', content: '<p style="text-align:justify">To check the nego roadmap, first select the product category you are interested in, then click on "Nego Roadmap" (on the horizontal menu below the section´s title). You will see the full list of programs associated with that product category, by clicking on each of them you can see the basic information associated to them.</p>' },
    ];

  const getStyle = () => {
    return {
      color: 'rgb(228, 27, 19)',
    };
  };

  return (
    <Dialog
      onClose={onClose}
      open
      maxWidth="md"
      sx={{ textAlign: 'center', '.MuiDialogTitle-root': getStyle() }}
      fullWidth
    >
      <DialogTitle onClose={onClose} >Frequently Asked Questions for my.BuyIn.pro</DialogTitle>
      <Box p={1}>
        {FAQ.map((q) => <Accordion
          expanded={expanded === q.identifiant}
          onChange={handleChange(q.identifiant)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: 'bold' }}>{q.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <span
              dangerouslySetInnerHTML={{
                __html: q.content,
              }}
            ></span>
          </AccordionDetails>
        </Accordion>
        )}

      </Box>
    </Dialog>
  );
}
