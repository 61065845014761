import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { Divider, Link } from '@mui/material';
import apiClient from '../../../services/apiClient';
import { ReportList } from './ReportList';
import { ToolCard } from './ToolCard';
import DeviceNegotiationAlertToolIcon from '../../shared/Icons/tools/DeviceNegotiationAlertToolIcon';
import Guard from '../../shared/Guard/Guard';
import { useAuth } from '../../AuthProvider';
import { ErrorAlert } from '../../ui/mui/ErrorAlert/ErrorAlert';

export interface PowerBIReport {
  id: string;
  name: string;
  iconId: number;
  isPaginated: boolean;
  powerBIId: string;
  powerBIParameter: string;
  filters: any[];
  settings: any[];
  hasPageId: boolean;
  pageId: string;
  embedConfiguration: any;
}

function NegotiationAutomationTool() {
  const { profile } = useAuth();
  const { trackPageView } = useMatomo();
  return (
    <Guard
      role={profile?.isCustomer ? 'TOOLS_DP-LLNB-LIVE' : 'ROLE_REPORT_USER'}
    >
      <Link
        underline="none"
        href="https://rpa.buyin.pro/"
        target="_blank"
        rel="noopener"
      >
        <ToolCard
          label="Negotiation Automation Robot"
          icon={<DeviceNegotiationAlertToolIcon />}
          color="rgb(255,210,48)"
          onClick={() => {
            trackPageView({
              documentTitle: 'Tools/Robot',
            });
          }}
        />
      </Link>
    </Guard>
  );
}

export default function PowerBiReports({
  setPowerBIReports,
}: {
  setPowerBIReports?: (reports: PowerBIReport[]) => void;
}) {
  const [searchParams] = useSearchParams();
  const [reports, setReports] = useState<PowerBIReport[]>([]);
  const { trackPageView } = useMatomo();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchReports = () => {
    apiClient
      .get('/reports', {
        // :todo: add domain filter
      })
      .then((res: any) => {
        setReports(res.data.records);
        setPowerBIReports?.(res.data.records);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setError('Something went wrong');
      });
  };

  const filterReport = ({
    report,
    isBefore,
  }: {
    report: PowerBIReport;
    isBefore: boolean;
  }) => {
    const isPaginated = report.isPaginated;
    const hasMarketIcon = report.iconId === 4 || report.iconId === 5;
    if (isBefore) {
      return isPaginated || (!isPaginated && hasMarketIcon);
    }
    return !isPaginated && !hasMarketIcon;
  };

  const handleReportClick = (report: PowerBIReport) => {
    trackPageView({
      documentTitle: `Tools/PBI/${report.name}`,
    });
    navigate(`/domains/tools/powerBI/${report.id}`);
  };

  useEffect(() => {
    setIsLoading(true);
    setPowerBIReports?.([]);
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return isLoading ? null : (
    <>
      <Divider sx={{ marginBottom: 2 }} />
      {error && (
        <ErrorAlert
          timeout={10}
          message={error}
          onClose={() => setError(null)}
        />
      )}
      <ReportList
        reports={reports.filter((report) =>
          filterReport({ report, isBefore: true }),
        )}
        onReportClick={(report) => handleReportClick(report)}
      />
      <NegotiationAutomationTool />
      <ReportList
        reports={reports.filter((report) =>
          filterReport({ report, isBefore: false }),
        )}
        onReportClick={(report) => handleReportClick(report)}
      />
    </>
  );
}
