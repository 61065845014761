import { useEffect, useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import { Theme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import apiClient from '../../../../services/apiClient';

const ContactSkeleton = () => (
  <Stack spacing={2} direction="row" padding={2}>
    <Box>
      <Skeleton animation="wave" variant="circular" width={50} height={50} />
    </Box>
    <Box sx={{ py: 1 }} width="60%">
      <Skeleton
        animation="wave"
        height={10}
        width="100%"
        style={{ marginBottom: 6 }}
      />
      <Skeleton animation="wave" height={10} width="100%" />
    </Box>
  </Stack>
);

const ContactDialog = ({
  contact,
  open,
  onClose,
  onNavigate,
}: {
  contact: any;
  open: boolean;
  onClose: () => void;
  onNavigate: () => void;
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    backDrop: {
      backdropFilter: 'blur(3px)',
    },
  }));
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleSupplierClick = (supplierId: number) => {
    const search = createSearchParams();
    if (searchParams.get('navDomain')) {
      search.set('navDomain', searchParams.get('navDomain') || '');
    }
    if (searchParams.get('navGroup')) {
      search.set('navGroup', searchParams.get('navGroup') || '');
    }
    navigate({
      pathname: `/suppliers/${supplierId}`,
      search: search.toString(),
    });
    onNavigate();
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <DialogTitle>
          <Stack direction="row" spacing={2} paddingY={0} alignItems="center">
            <Avatar
              alt={`${contact.firstName} ${contact.lasttName}}`}
              src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${contact.email}`}
              sx={{ width: 87, height: 87 }}
            />
            <Box>
              <Typography>{contact.jobTitle}</Typography>
              <Typography color="gray">
                {contact.category?.name ||
                  contact.subcategory?.name ||
                  contact.otherCategory ||
                  contact?.domain?.name}
              </Typography>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <Stack>
            <Typography>
              <Box sx={{ typography: 'subtitle2' }}>
                Address: <br />
                <Stack direction="row" gap={1}>
                  <LocationOnIcon color="primary" />
                  <Typography>{contact.street}</Typography>
                </Stack>
              </Box>
              {contact.postCode}, {contact.city}
              <Divider />
              <Box sx={{ typography: 'subtitle2' }}>
                Contact: <br />
              </Box>
              <Stack direction="row" gap={1}>
                <PhoneIphoneIcon color="primary" />
                <Typography>{contact.mobile}</Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <PhoneIcon color="primary" />
                <Typography>{contact.fixed}</Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <EmailIcon color="primary" />
                <Typography>{contact.email}</Typography>
              </Stack>
              {contact?.suppliers?.length > 0 && (
                <>
                  <Divider />
                  <Box sx={{ typography: 'subtitle2' }}>
                    Suppliers: <br />
                  </Box>
                  <Box
                    sx={{
                      mb: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'hidden',
                      overflowX: 'scroll',
                      padding: 1,
                    }}
                  >
                    <Stack direction="row" gap={1}>
                      {(contact.suppliers || []).map((s: any, i: number) => (
                        <Chip
                          key={i}
                          label={s.name}
                          variant="outlined"
                          onClick={() => handleSupplierClick(s.id)}
                        />
                      ))}
                    </Stack>
                  </Box>
                </>
              )}
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default function ContactsSearch({
  filter,
  onNavigate,
}: {
  filter: any;
  onNavigate: () => void;
}) {
  const [contacts, setContacts] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isClicked, setIsClicked] = useState(false);
  const [contact, setContact] = useState();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { search, domain, category, subcategory, group } = filter;

  useEffect(() => {
    if (!isClicked || !search) {
      setContacts(null);
      setPage(1);
    }
    if (search) {
      setIsLoading(true);
      apiClient
        .get('/contacts', {
          params: {
            page,
            group,
            sortBy: 'firstName',
            order: 'ASC',
            perPage: 3,
            search,
            hasOtherCategory:
              location.pathname.indexOf('/domains') === -1 || null,
          },
        })
        .then((res) => {
          if (isClicked) {
            res.data.records = (contacts?.records || []).concat(
              res.data.records,
            );
          }
          setContacts(res.data);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setIsClicked(false);
          setIsLoading(false);
        });
    }
  }, [search, domain, category, subcategory, group, page]);

  return (
    <>
      {contact && (
        <ContactDialog
          open={open}
          contact={contact}
          onClose={() => {
            setOpen(false);
          }}
          onNavigate={() => {
            setOpen(false);
            onNavigate();
          }}
        />
      )}
      {(contacts?.pagination?.total > 0 || isLoading) && (
        <Box sx={{ mt: 2 }}>
          <Divider />
          <Stack direction="row" paddingY={1.5} p={1} spacing={1}>
            <Typography color="primary">
              <RecentActorsIcon />
            </Typography>
            <Typography color="gray">
              Contacts {contacts && `(${contacts?.pagination?.total})`}
            </Typography>
          </Stack>
          <Divider />
        </Box>
      )}
      {contacts?.records?.map((contact: any, i: number) => (
        <CardActionArea
          sx={{ p: 1 }}
          key={i}
          onClick={() => {
            setContact(contact);
            setOpen(true);
          }}
        >
          <Stack direction="row" spacing={1} paddingY={0} alignItems="center">
            <Avatar
              alt={`${contact.firstName} ${contact.lasttName}}`}
              src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${contact.email}`}
              sx={{ width: 50, height: 50 }}
            />
            <Box>
              <Typography>
                {contact.firstName}, {contact.lastName}
              </Typography>
              <Typography color="gray">{contact.jobTitle}</Typography>
            </Box>
          </Stack>
        </CardActionArea>
      ))}
      {isLoading && <ContactSkeleton />}
      {contacts?.records?.length !== 0 &&
        contacts?.records?.length < contacts?.pagination?.total && (
          <>
            <Divider />
            <CardActionArea
              sx={{ p: 1, px: 2 }}
              onClick={() => {
                setIsLoading(true);
                setIsClicked(true);
                setPage(page + 1);
              }}
            >
              <Stack direction="row" spacing={1} p={1}>
                <ManageSearchIcon color="primary" />

                <Typography color="primary" style={{ marginTop: 2 }}>
                  Show more contacts
                </Typography>
              </Stack>
            </CardActionArea>
            <Divider />
          </>
        )}
    </>
  );
}
