import React from 'react';
import { useMatch, useNavigate } from 'react-router';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import HomeIcon from '@mui/icons-material/Home';
import Layout from '../Layout/Layout';
import TabPanel from '../ui/mui/TabPanel/TabPanel';
import ContactList from '../ContactList/ContactList';
import BuyinNewsList from '../news/BuyinNewsList/BuyinNewsList';
import SummitBooking from './SummitBooking/SummitBooking';

const homeTabs: Array<{ value: string; label: string }> = [
  { value: 'summit-booking', label: 'Summit Booking' },
  { value: 'buyin-news', label: 'Buyin news' },
  { value: 'contacts', label: 'Contacts' },
];

function HomeTabs() {
  const match = useMatch('/:tab/*');
  const navigate = useNavigate();
  const matchedTab: string = match?.params.tab || '';
  const { trackPageView } = useMatomo();

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newTab: any) => {
    if (newTab === 'contacts') {
      trackPageView({
        documentTitle: 'Home/Directory_All',
      });
    } else {
      trackPageView({
        documentTitle: 'Home/' + capitalizeFirstLetter(`${newTab}`),
      });
    }
    navigate({ pathname: `/${newTab}` });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" alignItems="center" gap={1} sx={{ p: 2 }}>
        <HomeIcon color="primary" sx={{ fontSize: 40 }} />
        <Typography component="h1" variant="h5">
          Home
        </Typography>
      </Stack>
      <Divider />
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs value={matchedTab} onChange={handleChangeTab} variant="fullWidth">
          {homeTabs.map((tab) => (
            <Tab value={tab.value} key={tab.value} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={matchedTab} index={'summit-booking'}>
        <SummitBooking />
      </TabPanel>
      <TabPanel value={matchedTab} index={'buyin-news'}>
        <BuyinNewsList />
      </TabPanel>
      <TabPanel value={matchedTab} index={'contacts'}>
        <ContactList />
      </TabPanel>
    </Box>
  );
}

export default function HomePage() {
  return (
    <Layout noBreadcrumbs>
      <HomeTabs />
    </Layout>
  );
}
