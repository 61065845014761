import paginatedReportIcon from '../../../images/powerBi/paginated_power.png';
import PowerBIIcon from '../../shared/Icons/PowerBiIcon';
import RefurbishedDashboardIcon from '../../shared/Icons/tools/RefurbishedDashboardIcon';
import MarketplaceIcon from '../../shared/Icons/tools/Marketplace';
import MarketIntelligencePackIcon from '../../shared/Icons/tools/MarketIntelligencePackIcon';
import LiveLocalNegotiationIcon from '../../shared/Icons/tools/LiveLocalNegotiationIcon';
import CashInDashboardIcon from '../../shared/Icons/tools/CashInDashboardIcon';
import PCDashboardIcon from '../../shared/Icons/tools/PCDashboardIcon';
import EcoRatingBenchmarkIcon from '../../shared/Icons/tools/EcoRatingBenchmarkIcon';
import RetailPriceReviewIcon from '../../shared/Icons/tools/RetailPriceReviewIcon';
import { ToolCard } from './ToolCard';
import { PowerBIReport } from './PowerBiReports';
import RefurbishedRetailPriceIcon from '../../shared/Icons/tools/RefurbishedRetailPriceIcon';
import UpdateRefurbishedRetailPriceIcon from '../../shared/Icons/tools/UpdateRefurbishedRetailPriceIcon';

export const REPORT_LOGOS: any[] = [
  {
    value: 10,
    name: 'Retail Price Review',
    icon: <RetailPriceReviewIcon />,
    order: 0,
  },
  {
    value: 4,
    name: 'Marketplace',
    icon: <MarketplaceIcon />,
    color: 'rgb(255,210,48)',
    order: 1,
  },
  {
    value: 5,
    name: 'Market Intelligence Pack',
    icon: <MarketIntelligencePackIcon />,
    color: 'rgb(255,210,48)',
    order: 2,
  },
  {
    value: 9,
    name: 'Eco Rating Benchmark',
    icon: <EcoRatingBenchmarkIcon />,
    order: 4,
  },
  {
    value: 8,
    name: 'PC Dashboard',
    icon: <PCDashboardIcon />,
    order: 5,
  },
  {
    value: 6,
    name: 'Live Local Negotiation',
    icon: <LiveLocalNegotiationIcon />,
    order: 6,
  },
  {
    value: 7,
    name: 'Cash-In Dashboard',
    icon: <CashInDashboardIcon />,
    order: 7,
  },
  {
    value: 3,
    name: '360° Refurbished Dashboard',
    icon: <RefurbishedDashboardIcon />,
    order: 8,
  },
  {
    value: 11,
    name: 'Refurbished Retail Price',
    icon: <RefurbishedRetailPriceIcon />,
    order: 8,
  },
  {
    value: 12,
    name: 'Update Refurbished Retail Price',
    icon: <UpdateRefurbishedRetailPriceIcon />,
    order: 8,
  },
  {
    value: 1,
    name: 'Power BI Icon',
    icon: <PowerBIIcon />,
    order: 9,
  },
  {
    value: 2,
    name: 'Report Icon',
    icon: (
      <img
        src={paginatedReportIcon}
        alt="Report Icon"
        style={{ width: '50px', height: '50px' }}
      />
    ),
    order: 10,
  },
];

function getReportLogo(report: PowerBIReport) {
  return REPORT_LOGOS.find((logo) => logo.value === report.iconId);
}
function sortReports(a: PowerBIReport, b: PowerBIReport) {
  return a.isPaginated
    ? -1
    : (REPORT_LOGOS.find((logo) => logo.value === a.iconId)?.order || 0) -
        (REPORT_LOGOS.find((logo) => logo.value === b.iconId)?.order || 0);
}

export function ReportList({
  reports,
  onReportClick,
}: {
  reports: any[];
  onReportClick: (report: PowerBIReport) => void;
}) {
  return (
    <>
      {(reports || [])
        .sort(sortReports)
        .map((report: PowerBIReport, index: number) => (
          <ToolCard
            label={report.name}
            icon={getReportLogo(report)?.icon}
            color={
              getReportLogo(report)?.color ||
              (report.isPaginated ? 'primary.main' : '#047F8C')
            }
            onClick={() => {
              onReportClick(report);
            }}
            key={index}
          />
        ))}
    </>
  );
}
