import { useEffect, useState } from 'react';
import FeedIcon from '@mui/icons-material/Feed';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import apiClient from '../../../../services/apiClient';
import ContractDetail from '../../../ContractList/ContractDetail/ContractDetail';
import Button from '@mui/material/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function ContractsSearch({
  filter,
  onNavigate,
}: {
  filter: any;
  onNavigate: () => void;
}) {
  const [contracts, setContracts] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [contractId, setContractId] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const { search, domain, category, group } = filter;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isClicked || !search) {
      setContracts(null);
      setPage(1);
    }
    if (search) {
      setIsLoading(true);

      apiClient
        .get('/contracts', {
          params: {
            group,
            search,
            page,
            perPage: 3,
            statuses: '1,2,3',
          },
        })
        .then((res) => {
          if (isClicked) {
            res.data.records = (contracts?.records || []).concat(
              res.data.records,
            );
          }
          setContracts(res.data);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setIsClicked(false);
          setIsLoading(false);
        });
    }
  }, [search, page]);

  const handleViewAll = () => {
    searchParams.delete('search');
    searchParams.set('search', search.toString());
    setSearchParams(searchParams);
    navigate({
      pathname: `/domains/contracts`,
      search: searchParams.toString(),
    });
    onNavigate();
  };

  return (
    <>
      {(contracts?.records?.length > 0 || isLoading) && (
        <Box sx={{ mt: 2 }}>
          <Divider />
          <Stack direction="row" paddingY={1.5} p={1} spacing={1}>
            <Typography color="primary">
              <FeedIcon />
            </Typography>
            <Typography color="gray">
              Contracts {contracts && `(${contracts?.pagination.total})`}
            </Typography>
          </Stack>
          <Divider />
          {(contracts?.records || []).map((contract: any, i: number) => (
            <CardActionArea
              key={i}
              sx={{ p: 1, px: 2 }}
              onClick={() => {
                setContractId(contract.id);
              }}
            >
              <Box sx={{ typography: 'subtitle2' }}>{contract.name}</Box>
              <Typography color="gray">
                <Stack direction="row" spacing={0.5}>
                  <Box>{contract.type}</Box>
                </Stack>
              </Typography>
            </CardActionArea>
          ))}
          <Divider />
        </Box>
      )}

      {isLoading && (
        <>
          <Box sx={{ p: 2 }} width="100%">
            <Skeleton
              animation="wave"
              height={10}
              width="40%"
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={10} width="60%" />
          </Box>
        </>
      )}
      {contracts?.records?.length !== 0 &&
        contracts?.records?.length < contracts?.pagination?.total && (
          <CardActionArea
            sx={{ p: 1, px: 2 }}
            onClick={() => {
              setIsLoading(true);
              setIsClicked(true);
              setPage(page + 1);
            }}
          >
            <Stack direction="row" spacing={1} p={1}>
              <ManageSearchIcon color="primary" />
              <Typography color="primary" style={{ marginTop: 2 }}>
                Show more contracts
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                flexGrow={1}
                justifyContent="flex-end"
              >
                <Button onClick={handleViewAll} variant="outlined">
                  Open Contracts
                </Button>
              </Stack>
            </Stack>
          </CardActionArea>
        )}
      <Divider />
      {contractId && (
        <ContractDetail
          handleContractDialogClose={() => setContractId(null)}
          contractId={Number(contractId)}
          isFeed={false}
        />
      )}
    </>
  );
}
