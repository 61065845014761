import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import PayloadProvider from '../../news/PayloadProvider';
import { POST_TYPES } from '../../news/data';
import NewsFeed from './NewsFeed/NewsFeed';
import ContactFeed from './ContactFeed/ContactFeed';
import ContractFeed from './ContractFeed/ContractFeed';
import SpendFeed from './SpendFeed/SpendFeed';
import NegotiationFeed from './NegotiationFeed/NegotiationFeed';
import { useFilters } from '../../Layout/NavigationFiltersProvider/NavigationFiltersProvider';

export default function FeedTab() {
  const { trackPageView } = useMatomo();
  const [isTracked, setIsTracked] = useState(false);
  const [allDomains, setAllDomains] = useState<boolean>(true);
  const [hasPublicNews, setHasPublicNews] = useState(false);
  const [hasUpdates, setHasUpdates] = useState(false);
  const [hasBuyinNews, setHasBuyinNews] = useState(false);
  const [hasStrategies, setHasStrategies] = useState(false);
  const [hasContacts, setHasContacts] = useState(false);
  const [hasContracts, setHasContracts] = useState(false);
  const [hasNegotiations, setHasNegotiations] = useState(false);

  const {
    domains,
    categories,
    subcategories,
    groups,
    regions,
    natcos,
    isReady,
    resetTrigger,
  } = useFilters();

  const resetHasData = () => {
    setHasNegotiations(true);
    setHasPublicNews(true);
    setHasUpdates(true);
    setHasBuyinNews(true);
    setHasStrategies(true);
    setHasContacts(true);
    setHasContracts(true);
    setAllDomains(true);
  };

  useEffect(() => {
    if (isReady) {
      resetHasData();
      setAllDomains(
        domains?.length === 0 &&
          categories?.length === 0 &&
          subcategories?.length === 0,
      );
    }
  }, [categories, subcategories, groups, regions, natcos, isReady]);

  useEffect(() => {
    resetHasData();
  }, [resetTrigger]);

  useEffect(() => {
    if (!isTracked) {
      console.log('trackPageView FeedTab');
      trackPageView({
        documentTitle: 'Feed',
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  return (
    <Box sx={{ p: 1 }}>
      <Masonry columns={2}>
        {hasUpdates && (
          <Box sx={{ minHeight: '10px' }}>
            <PayloadProvider type={POST_TYPES.UPDATES} fromSearch={false}>
              <NewsFeed
                type={POST_TYPES.UPDATES}
                setHasArticles={setHasUpdates}
              />
            </PayloadProvider>
          </Box>
        )}
        {hasBuyinNews && allDomains && (
          <Box sx={{ minHeight: '10px' }}>
            <PayloadProvider type={POST_TYPES.UPDATES} fromSearch={false}>
              <NewsFeed
                type={POST_TYPES.BUYIN_NEWS}
                setHasArticles={setHasBuyinNews}
              />
            </PayloadProvider>
          </Box>
        )}
        {hasPublicNews && (
          <Box sx={{ minHeight: '10px' }}>
            <PayloadProvider type={POST_TYPES.UPDATES} fromSearch={false}>
              <NewsFeed
                type={POST_TYPES.PUBLIC_NEWS}
                setHasArticles={setHasPublicNews}
              />
            </PayloadProvider>
          </Box>
        )}
        {hasStrategies && (
          <Box sx={{ minHeight: '10px' }}>
            <PayloadProvider type={POST_TYPES.STRATEGIES} fromSearch={false}>
              <NewsFeed
                setHasArticles={setHasStrategies}
                type={POST_TYPES.STRATEGIES}
              />
            </PayloadProvider>
          </Box>
        )}
        <Box>
          <SpendFeed
            page="ReportSectiond8142cd406d58a32da7b"
            height="39vh"
            title="Top Suppliers"
          />
        </Box>
        <Box>
          <SpendFeed
            page="ReportSection5a8706aa5dd099732864"
            height="40vh"
            title="Spend developement"
          />
        </Box>
        {hasNegotiations && (
          <Box sx={{ minHeight: '10px' }}>
            <NegotiationFeed setHasNegotiations={setHasNegotiations} />
          </Box>
        )}
        {hasContracts && (
          <Box sx={{ minHeight: '10px' }}>
            <ContractFeed setHasContracts={setHasContracts} />
          </Box>
        )}
        {hasContacts && (
          <Box sx={{ minHeight: '10px' }}>
            <ContactFeed setHasContacts={setHasContacts} />
          </Box>
        )}
      </Masonry>
    </Box>
  );
}
