import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import LockIcon from '@mui/icons-material/Lock';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DialogList from '../../../ui/mui/DialogList/DialogList';
import SessionForm from './SessionForm/SessionForm';
import apiClient from '../../../../services/apiClient';
import { Domain, Group } from '../../../../types';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ConfirmDialog from '../../../ui/mui/ConfirmDialog/ConfirmDialog';
interface Session {
  id: number;
  title: string;
  group: Partial<Group>;
  speakers: any[];
  description: string;
  date: Date;
  duration: number;
  type: string;
  isLocked: boolean;
  article: { id: number };
  link?: string;
  domains?: any[];
}

export default function SessionManagement() {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const [openSpeakers, setOpenSpeakers] = useState(false);
  const [openDomains, setOpenDomains] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [initialValues, setInitialValues] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialize, setInitialize] = useState(true);
  const [speakers, setSpeakers] = useState([]);
  const [groupRecords, setGroupRecords] = useState<Partial<Group>[]>([]);
  const [domainRecords, setDomainRecords] = useState<Domain[]>([]);
  const [choices, setChoices] = useState<
    {
      name: string;
      value: string;
      type: string;
    }[]
  >([]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 5 },
    {
      field: 'title',
      headerName: 'Title',
      width: 80,
      editable: true,
      renderCell: (params: any) => (
        <Tooltip title={params.row.title}>
          <Typography noWrap maxWidth={80}>
            {params.row.title}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      editable: true,
      renderCell: (params: any) => (
        <Tooltip title={params.row.description}>
          <Typography noWrap maxWidth={150}>
            {params.row.description}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'link',
      headerName: 'Link',
      editable: true,
      width: 150,
      renderCell: (params: any) => (
        <Typography noWrap maxWidth={150}>
          <Link href={params.row.link}> {params.row.link}</Link>
        </Typography>
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      type: 'dateTime',
      width: 100,
      editable: true,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 20,
      editable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 20,
      editable: true,
    },
    {
      field: 'isLocked',
      headerName: 'Locked',
      width: 5,
      editable: true,
      type: 'boolean',
      renderCell: (params: any) => (params.row.isLocked ? <LockIcon /> : ''),
    },
    {
      field: 'group',
      headerName: 'Group',
      width: 5,
      editable: true,
      type: 'singleSelect',
      valueOptions: groupRecords.map((group) => group.name || ''),
      renderCell: (params: any) => {
        return `${params.row.group.code || ''}`;
      },
    },
    {
      field: 'domains',
      headerName: 'Domains',
      width: 100,
      editable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Tooltip
              title={
                (params.row.domains || [])
                  .map((d: any) => `${d?.name}`)
                  .join(', ') || 'ALL'
              }
            >
              <Chip
                label={`${params.row?.domains[0]?.code || 'ALL'}`}
                variant="outlined"
              />
            </Tooltip>
            {params.row.domains?.length > 1 ? (
              <Badge
                badgeContent={` +${params.row.domains.length - 1}`}
                color="primary"
              ></Badge>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      field: 'speakers',
      headerName: 'Speakers',
      width: 100,
      editable: true,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.speakers.length > 0 && (
              <>
                <Tooltip
                  title={params.row.speakers
                    .map((s: any) => `${s?.firstName} ${s?.lastName}`)
                    .join(', ')}
                >
                  <Chip
                    avatar={
                      <Avatar
                        alt={params.row.speakers[0].lastName[0]}
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${params.row.speakers[0].email}`}
                      />
                    }
                    label={`${params.row.speakers[0].firstName[0]}.${params.row.speakers[0].lastName[0]}`}
                    variant="outlined"
                  />
                </Tooltip>
                {params.row.speakers.length > 1 ? (
                  <Badge
                    badgeContent={` +${params.row.speakers.length - 1}`}
                    color="primary"
                  ></Badge>
                ) : (
                  ''
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'article',
      headerName: 'Article id',
      editable: true,
      width: 5,
      renderCell: (params: any) => params?.row?.article?.id || '',
    },
  ];

  const [rows, setRows] = useState<Session[]>([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleDelete = () => {
    if (selectedIds.length > 0) {
      apiClient
        .delete(`admin/sessions/${selectedIds.join(',')}`)
        .then(() => {
          setInitialize(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const notifyChanges = () => {
    if (selectedIds.length > 0) {
      apiClient
        .post(`/enrollments/notify-changes/${selectedIds.join(',')}`)
        .then(() => {
          setInitialize(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const processRowUpdate = (session: any) => {
    session.article =
      session.article && !isNaN(session.article)
        ? {
            id: Number(session.article),
            type: 'category-vendor-news',
          }
        : {};
    apiClient
      .put(`admin/sessions/${session.id}`, {
        ...session,
        group: groupRecords.find((group) => group.name === session.group),
      })
      .then((res: any) => {
        setInitialize(true);
      })
      .catch((err: any) => {
        console.log(err);
      });
    return session;
  };

  useEffect(() => {
    if (initialize) {
      setIsLoading(true);
      setInitialize(false);
      apiClient
        .get('admin/sessions')
        .then((res) => {
          setRows(res.data.records);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      if (speakers.length === 0) {
        apiClient
          .get('/contacts', {
            params: { perPage: 0, hasSessions: true, hasOtherCategory: true },
          })
          .then((res: any) => res.data.records)
          .then((contacts) => {
            apiClient
              .get('/speakers', { params: { perPage: 0 } })
              .then((res: any) => {
                let allSpeakers = contacts
                  .concat(res.data.records)
                  .sort((a: any, b: any) =>
                    a.firstName > b.firstName
                      ? 1
                      : a.firstName < b.firstName
                      ? -1
                      : 0,
                  );
                setSpeakers(allSpeakers);
                setChoices(
                  allSpeakers.map((speaker: any) => ({
                    name: `${speaker.firstName} ${speaker.lastName}`,
                    value: `${speaker.id}`,
                    type: (speaker.isContact && 'contact') || 'speaker',
                  })),
                );
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (groupRecords.length === 0) {
        apiClient
          .get('/groups', { params: { isVisible: true } })
          .then((res) => {
            setGroupRecords(res.data.records);
          });
      }
      if (domainRecords.length === 0) {
        apiClient
          .get('/domains', { params: { isVisible: true, outOfScope: false } })
          .then((res) => {
            setDomainRecords(res.data.records);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize]);
  return (
    <>
      <SessionForm
        open={open}
        setOpen={setOpen}
        groupRecords={groupRecords}
        domainRecords={domainRecords}
        onChange={() => {
          setInitialize(true);
        }}
      />
      <DialogList
        choices={choices}
        title="Speakers"
        setOpen={setOpenSpeakers}
        onClose={() => {
          setOpenSpeakers(false);
        }}
        onChange={(selection: any[]) => {
          if (selectedRow && selectedRow.speakers) {
            selectedRow.speakers = speakers.filter((s: any) =>
              selection.some(
                (sel: any) =>
                  sel.type === ((s.isContact && 'contact') || 'speaker') &&
                  sel.value === String(s.id),
              ),
            );
            setRows([...rows]);
            apiClient
              .put(`admin/sessions/${selectedRow.id}`, selectedRow)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }}
        open={openSpeakers}
        initialValues={initialValues}
      />
      <DialogList
        choices={domainRecords.map((domain) => ({
          name: domain.name,
          value: String(domain.id),
          type: 'domain',
        }))}
        title="Domains"
        setOpen={setOpenDomains}
        onClose={() => {
          setOpenDomains(false);
        }}
        onChange={(selection: any[]) => {
          if (selectedRow && selectedRow.domains) {
            selectedRow.domains = domainRecords.filter((s: any) =>
              selection.some((sel: any) => sel.value === String(s.id)),
            );
            setRows([...rows]);
            apiClient
              .put(`admin/sessions/${selectedRow.id}`, selectedRow)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }}
        open={openDomains}
        initialValues={initialValues}
      />
      <ConfirmDialog
        title="Confirm deleting sessions"
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={handleDelete}
      >
        <Typography>
          This action will delete also all the enrollments of the selected
          sessions. Are you sure ?
        </Typography>
      </ConfirmDialog>
      <Box sx={{ p: 1 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add session
          </Button>
          <Button
            disabled={selectedIds.length === 0}
            onClick={() => {
              setOpenConfirm(true);
            }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete selection
          </Button>
          <Button
            disabled={selectedIds.length === 0 || isLoading}
            onClick={notifyChanges}
            variant="outlined"
            startIcon={<NotificationsActiveIcon />}
          >
            Notify changes
          </Button>
        </Stack>

        <Box sx={{ mt: 3 }}>
          <DataGrid
            onSelectionModelChange={(ids: any) => {
              setSelectedIds(ids);
            }}
            loading={isLoading}
            onCellClick={(params: { id: any; field: string }) => {
              const row: any = rows.find((row) => row.id === params.id);
              setSelectedRow(row);

              if (params.field === 'speakers') {
                setInitialValues(
                  (row?.speakers || []).map((speaker: any) => ({
                    name: `${speaker.firstName} ${speaker.lastName}`,
                    value: `${speaker.id}`,
                    type: (speaker.isContact && 'contact') || 'speaker',
                  })),
                );
                setOpenSpeakers(true);
              }
              if (params.field === 'domains') {
                setInitialValues(
                  (row?.domains || []).map((domain: any) => ({
                    name: `${domain.name}`,
                    value: `${domain.id}`,
                    type: 'domain',
                  })),
                );
                setOpenDomains(true);
              }
            }}
            autoHeight
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            processRowUpdate={processRowUpdate}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </Box>
    </>
  );
}
